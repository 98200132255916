<template>
  <div class="game-landing-container">
    <v-img src="/static/image/game/background/special.png"></v-img>
    <div class="game-vendor-selection-list-container">
      <div class="game-vendor-selection-list">
        <div class="game-vendor-selection-item" v-for="provider in gameProvider" :key="provider.providerCode">
          <v-btn
            @click="changeVendor(provider.providerCode)"
            :class="selectedVendor == provider.providerCode ? 'active' : ''"
            height="auto"
            min-height="auto"
            class="text-center font-weight-bold my-0 mx-2 py-4 px-6 title game-vendor-selection-item-button white--text"
          >
            <label>{{ stringFormat('{0} {1}', $t(`gameVendor.${provider.providerCode}_short`), $t(`label.${gameType}`)) }}</label>
          </v-btn>
        </div>
      </div>
    </div>
    <div class="vendor-game-info">
      <v-row justify="space-between" class="vendor-game-info-box ma-auto">
        <v-col class="shrink game-vendor-logo-play-button-placeholder" cols="auto">
          <div class="vendor-info-box">
            <img class="vendor-icon" :src="vendorIconSrc()" />
            <p class="vendor-desc ma-0 white--text text-justify d-none">
              Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.
            </p>
          </div>
          <app-button v-if="!isGameMaintenance(gameType, selectedVendor)" :action="this.accessRealPlay" :title="$t(`label.playNow`)"></app-button>
          <app-button v-if="!isGameMaintenance(gameType, selectedVendor)" :action="this.accessDemo" :title="$t(`label.tryDemoNow`)"></app-button>

          <app-button
            v-else
            :customClass="'buttonPrimary white--text mt-3 subtitle-1 game-vendor-play-button'"
            :action="this.gameUnderMaintenanceDialog"
            :title="$t(`label.underMaintenance`)"
          ></app-button>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="shrink game-vendor-image-placeholder" cols="auto">
          <img class="vendor-game-image" :src="vendorGameSrc()" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { GAME_PROVIDERS_TYPE_CONFIG } from '@/store/game.module'
import { ROUTE_NAME } from '@/constants/route.constants'
import format from 'string-format'
import { uiHelper, locale } from '@/util'
import { CACHE_KEY } from '@/constants/constants'
import { GAME_TYPE_GAMES } from '@/store/game.module'
export default {
  name: 'special',
  data: () => ({
    currentLanguage: uiHelper.getLanguage(),
    gameType: 'special',
    SpecialGames: [],
    selectedVendor: '',
    gameProvider: [],
    stringFormat: format,
    storageGameProviderType: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_PROVIDER_TYPE}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`),
    storageSpecialGames: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_LIST}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}_` + 'special')
  }),
  computed: {
    ProviderTypes() {
      return this.$store.state.game.ProviderTypes
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    SpecialGamesState() {
      return this.$store.state.game.SpecialGames
    }
  },
  created() {
    this.getGame()
    this.initSelectedVendor()
  },
  watch: {
    ProviderTypes() {
      this.storageGameProviderType = this.$store.state.game.ProviderTypes
      this.initSelectedVendor()
    },
    SpecialGamesState() {
      this.storageSpecialGames = this.$store.state.game.SpecialGames
    }
  },
  methods: {
    gameUnderMaintenanceDialog() {
      let dialog = this.$parent.$parent.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.launchGame`)
      dialog.dialogXButton = null
      dialog.message.push(locale.getMessage(`errorCodeDesc.4_21`))

      dialog.button.push({
        title: locale.getMessage(`label.close`),
        action: this.$parent.$parent.closePageDialog
      })
      this.$parent.$parent.openPageDialog(dialog)
    },
    isGameMaintenance(gameType, providerCode) {
      if (
        this.storageGameProviderType == null ||
        this.storageGameProviderType.find(x => x.type == gameType) == undefined ||
        this.storageGameProviderType.find(x => x.type == gameType).providers.find(x => x.providerCode == providerCode) == undefined
      ) {
        return false
      } else {
        return this.storageGameProviderType.find(x => x.type == gameType).providers.find(x => x.providerCode == providerCode).isMaintenance
      }
    },
    async getGame() {
      if (this.storageSpecialGames == null) {
        let Obj = {
          currency: uiHelper.getCurrency(),
          type: this.gameType,
          size: '',
          page: '',
          platform: uiHelper.getPlatform(),
          category: '',
          provider: '',
          mode: 'All'
        }
        this.$store.dispatch(`${GAME_TYPE_GAMES}`, { Obj })
      }
    },
    initSelectedVendor() {
      if (this.storageGameProviderType && this.storageGameProviderType.length > 0) {
        let filteredGame = this.storageGameProviderType.find(x => x.type == this.gameType)
        if (filteredGame != undefined && filteredGame.providers.length > 0) {
          this.gameProvider = filteredGame.providers
          this.selectedVendor = filteredGame.providers[0].providerCode
        }
      } else {
        this.getGameProviderTypeInfo()
      }
    },
    getGameProviderTypeInfo() {
      let providerTypeConfigObj = {
        currency: uiHelper.getCurrency(),
        platform: uiHelper.getPlatform()
      }
      this.$store.dispatch(`${GAME_PROVIDERS_TYPE_CONFIG}`, { providerTypeConfigObj })
    },
    changeVendor(vendorCode) {
      this.selectedVendor = vendorCode
      this.vendorIconSrc()
      this.vendorGameSrc()
    },
    accessRealPlay() {
      let type = this.gameType
      let providerCode = this.selectedVendor

      if (this.isLoggedIn) {
        let routeData = this.$router.resolve({
          name: ROUTE_NAME.LAUNCH_GAME_LOBBY,
          params: {
            provider: providerCode,
            type: type,
            lang: this.$route.params.lang
          },
          query: {
            demo: 0
          }
        })

        uiHelper.openNewTab(routeData.href, 'Game Play Lobby', 1200, 900)
      } else {
        this.$parent.goToLoginPage()
      }
    },
    accessDemo() {
      let type = this.gameType
      let providerCode = this.selectedVendor

      let routeData = this.$router.resolve({
        name: ROUTE_NAME.LAUNCH_GAME_LOBBY,
        params: {
          provider: providerCode,
          type: type,
          lang: this.$route.params.lang
        },
        query: {
          demo: 1
        }
      })
      uiHelper.openNewTab(routeData.href, 'Game Play Lobby', 1200, 900)
    },
    vendorGameSrc() {
      return `/static/image/game/background/${this.gameType}_${this.selectedVendor.toLowerCase()}.png`
    },
    vendorIconSrc() {
      return `/static/image/game/icon/${this.selectedVendor.toLowerCase()}.png`
    }
  }
}
</script>
<style lang="scss" scoped>
.game-landing-container {
  height: 850px;
  position: relative;
  .v-image {
    height: 100%;
  }
}
.game-vendor-selection-list-box {
  padding: 0 25px;
}
.game-vendor-selection-list-container {
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  .game-vendor-selection-list {
    text-align: center;
    margin-top: 35px;
    .game-vendor-selection-item {
      display: inline-block;
      padding: 0 10px;
      margin-bottom: 15px;
      .game-vendor-selection-item-button {
        background-color: rgba(0, 0, 0, 0.3);
        letter-spacing: 0;
        min-width: 150px !important;
        &.active {
          background-image: linear-gradient(to right, var(--v-primary_2-base), var(--v-primary-base));
        }
        label {
          cursor: pointer;
        }
      }
    }
  }
}

.vendor-game-info {
  position: absolute;
  width: 100%;
  bottom: 100px;
  .vendor-game-info-box {
    max-width: 1600px;
    height: 480px;
    .vendor-info-box {
      margin-top: 75px;
      width: 400px;
      .vendor-icon {
        height: 55px;
      }
      .vendor-desc {
        font-weight: normal !important;
        font-size: 18px;
      }
    }

    .vendor-game-image {
      width: 900px;
    }
  }
}

@media (max-width: 1650px) {
  .vendor-game-info .vendor-game-info-box .vendor-game-image {
    width: 850px;
  }
  .game-landing-container {
    height: 700px;
  }

  .vendor-game-info .vendor-game-info-box {
    height: auto;
    padding: 0px 20px;
  }

  .vendor-game-info {
    top: 125px;
  }
}
@media (max-width: 1500px) {
  .vendor-game-info .vendor-game-info-box .vendor-game-image {
    width: 750px;
  }
  .game-landing-container {
    height: 550px;
  }
}
@media (max-width: 1350px) {
}
@media (max-width: 1263px) {
  .game-vendor-logo-play-button-placeholder,
  .game-vendor-image-placeholder {
    display: block;
    width: 100%;
    text-align: center;
  }
  .vendor-game-info .vendor-game-info-box .vendor-info-box {
    width: 350px;
    margin: auto;
  }

  .game-landing-container {
    height: 660px;
  }
}
@media (max-width: 1024px) {
  .game-landing-container .v-image {
    height: 100%;
    position: absolute;
    width: 100%;
  }

  .game-vendor-selection-list-container {
    position: relative;
    padding-top: 10px;
  }

  .vendor-game-info {
    position: relative;
    top: 0;
    bottom: 0;
  }
  .game-landing-container {
    height: auto;
  }
}
@media (max-width: 959px) {
  .vendor-game-info .vendor-game-info-box .vendor-info-box .vendor-icon {
    height: 40px;
    width: auto;
  }
  .vendor-game-info .vendor-game-info-box .vendor-info-box {
    width: 250px;
    margin: auto;
  }
  .vendor-game-info .vendor-game-info-box .vendor-game-image {
    width: 100%;
  }
  .game-vendor-selection-item-button {
    label {
      font-size: 16px;
    }
  }
}
@media (max-width: 599px) {
  .game-vendor-selection-list-container .game-vendor-selection-list .game-vendor-selection-item {
    display: block;
    padding: 0 10px;
    margin-bottom: 15px;
  }
}
@media (max-width: 425px) {
  .vendor-game-info .vendor-game-info-box {
    padding: 0;
  }
  .vendor-game-info .vendor-game-info-box .vendor-info-box {
    width: 190px;
    margin: auto;
  }

  .vendor-game-info .vendor-game-info-box .vendor-info-box .vendor-icon {
    max-height: 35px;
    height: auto;
    width: auto;
    max-width: 100%;
  }
}
</style>
